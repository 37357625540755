/*eslint sort-keys: "error"*/

const diyLabels = {
  FACEBOOK_QUESTION_PART_1: "Would you like to have the link to your ",
  FACEBOOK_QUESTION_PART_2: " posted on a TAPinto Facebook page?",
  FACEBOOK_QUESTION_PART_2_FOR_PUBLICATIONS: " posted on a Facebook page?",
  PER_SITE: "Per TAPinto site",
  PER_PUBLICATION: "Per publication",
  TAPINTO_FACEBOOK_SITES: "TAPinto Facebook Sites",
  EXCERPT: "Excerpt",
  FACEBOOK_URL: "Your Business's Facebook URL ",
  SUBMIT_A_PRESS_RELEASE: "Submit a Press Release",
  SUBMIT_A_FREELANCE_ARTICLE_NEWS: "Submit a Freelance Article News",
  SUBMIT_A_CANDIDATE_STATEMENT: "Submit a Candidate Statement",
  SUBMIT_A_LETTER_TO_THE_EDITOR: "Submit a Letter to the Editor",
  SUBMIT_A_NEW_COLUMN_ARTICLE: "Submit a New Column Article",
  SUBMIT_AN_EVENT: "Submit an Event",
  SUBMIT_A_CLASSIFIED: "Submit a Classified",
  POLITICAL_CANDIDATES: "Political Campaigns & Advocacy",
  FOR_PROFIT: "For-Profit",
  NONPROFIT: "Nonprofit",
  GOVERNMENT: "Government",
  HEADLINE: "Headline",
  SUBHEADING: "Subheading",
  AUTHORS_NAME: "Author's name",
  WRITE_DESCRIPTION_ABOUT_YOUR_BUSINESS:
    "Write a description about your business:",
  SUBMIT_AND_CHECKOUT: "Submit & Checkout",
  PREVIEW_CONTENT: "Preview Content",
  SHOPPING_CART_FACEBOOK: " Facebook Shopping Cart",
  SHOPPING_CART_ADS_FREE: "Ads-free",
  NO_FACEBOOK_SELECTED: "No Facebook Pages Selected",
  NO_ADS_FREE_TOWNS_SELECTED: "No ads-free towns selected",
  SELECT_TAPINTO_SITES: "Publish my content on the following TAPinto sites:",
  SELECT_PUBLICATIONS: "Publish my content on the following publications:",
  TAPINTO_SITES_NETWORK: "TAPinto Sites in our Network",
  PUBLICATIONS_NETWORK: "Publications in our Network",
  SITES: " sites",
  LOADING: "Loading...",
  SEARCH_TAPINTO_SITE: "Search for a TAPinto site",
  SEARCH_FOR_A_PUBLICATION: "Search for a publication",
  SHOPPING_CART_DISCLAIMER:
    "Every submission is reviewed prior to publication and therefore, publication is not instantaneous. Paid content will be published within 72 hours of being submitted.  Should content not be published within 72 hours of being submitted, a refund will be issued if requested. ",
  SHOPPING_CART_DISCLAIMER_FOR_LEGAL_NOTICES:
    "Our editors will review it for publication and approve it within 72 hours so long as it abides by our Advertising Policy, Privacy Policy and Terms of Use. Once the legal notice is published, you will receive an email with a link to the legal notice for your records.",
  ERROR_TITLE: "Error Messages",
  ERROR_SUBTITLE:
    "We're sorry, errors prevented your contribution from being submitted.",
  CHOOSE_CATEGORIES: "Categories (choose at least one)",
  SHOW_MORE_CATEGORIES: "Show more categories",
  SHOW_LESS_CATEGORIES: "Show less categories",
  EVENT_NAME: "Event Name",
  DATE: "Date",
  EVENT_DATE: "Event Date",
  START_TIME: "Start Time",
  END_TIME: "End Time",
  TIME: "Time",
  ADD_MORE: "Add More",
  EVENT_ADDRESS: "Address",
  CITY: "City",
  STATE: "State",
  EVENT_MORE_THAN_ONE_DATE: "Does this event take place on more than one date?",
  REPEAT: "Repeat",
  ON: "On",
  EVERY: "Every",
  STARTS: "Starts",
  AFTER: "After",
  ENDS: "Ends",
  OCCURRENCES: "Occurrences",
  SUMMARY: "Summary",
  DONE: "Done",
  CANCEL: "Cancel",
  TIMES: "times",
  FROM: "from",
  TO: "to",
  LETTERS_TO_THE_EDITOR_INFO: "Letters to the Editor Policy",
  FREELANCE_ARTICLE_NEWS_INFO:
    "Freelance Articles are published at the sole discretion of TAPinto",
  PHONE_NUMBER: "Phone Number",
  CHOOSE_A_COLUMN: "Choose a column",
  COLUMNS: "Columns",
  THIRTY_DAYS_RENEWABLE: "Published for 30 days, renewable thereafter",
  NINETY_DAYS_RENEWABLE: "Published for 90 days, renewable thereafter",
  CONTENT_WILL_BE_SEEN_BY_ADMIN:
    "This information will be seen by site administrator",
  CONTACT_PERSON: "Contact Person",
  PHONE: "Phone",
  EMAIL: "Email",
  WEBSITE: "Website",
  FACEBOOK: "Facebook",
  TWITTER: "Twitter",
  INSTAGRAM: "Instagram",
  BUSINESS_NAME: "Business Name",
  BUSINESS_ADDRESS: "Business Address",
  ADDRESS_OPTIONAL: "Address (optional)",
  ZIP_CODE: "ZIP Code",
  FAX: "Fax",
  CONTACT_INFORMATION: "Contact Information",
  SUBMIT_A_NEW_DIRECTORY: "Submit a new Business Directory Listing",
  SUBMIT_A_MILESTONE: "Submit a Milestone",
  ZIPCODE: "Zipcode",
  CONTACT_INFO: "Contact Info",
  INFORMATION_SEEN_BY_SITE_ADMIN:
    "( This information will be seen by site administrator )",
  INFORMATION_PUBLIC: "( This information will be seen by the public )",
  VENUE: "Venue",
  SUBMIT_AN_OBITUARY: "Submit an Obituary",
  NAME_OF_DECEASED: "Name of Deceased",
  DATE_OF_BIRTH: "Date of Birth",
  DATE_OF_DEATH: "Date of Death",
  VISITATION: "Visitation",
  ADD_MORE_DATES_TIMES: "Add more dates/times",
  NAME: "Name",
  ADDRESS: "Address",
  SERVICE: "Service",
  BURIAL: "Burial",
  CREMATION: "Cremation",
  SUBMIT_A_REAL_ESTATE_LISTING: "Submit a Real Estate Listing",
  BEDROOMS: "Bedrooms",
  BATHROOMS: "Bathrooms",
  LOT_SIZE: "Lot Size",
  PRICE: "Price",
  MLS_NUMBER: "MLS Number",
  MLS_URL: "MLS URL",
  LISTING_URL: "Listing URL",
  REALTOR_NAME: "Realtor Name",
  AGENCY_NAME: "Agency Name",
  AGENCY_PHONE_NUMBER: "Agency Phone Number",
  SOLD: "Sold",
  UNDER_CONTRACT: "Under Contract",
  NUMBER_BATHROOMS_EXAMPLE: "Example: 3.5",
  LISTING_INFORMATION: "Listing Information",
  PROPERTY_INFORMATION: "Property Information",
  REALTOR_INFORMATION: "Realtor Information",
  PRIVATE_CONTACT_INFO: "Contact Information",
  THIS_WILL_BE_SEEN_BY_ADMIN:
    "This information will be seen by site administrator",
  THIS_WILL_BE_SEEN_BY_PUBLIC: "This information will be seen by the public",
  ALL_DAY: "All Day",
  VIRTUAL_EVENT: "Virtual Event",
  REGISTRATION_REQUIRED: "Is registration required?",
  BYLINE: "Byline",
  AT: "At",
  FOR_VERIFICATION_PURPOSES:
    "(For Verification Purposes Only; Will Not Be Published)",
  PRESS_RELEASE: "Press Release",
  EVENT: "Event Listing",
  BUSINESS_DIRECTORY_LISTING: "Business Directory Listing",
  PER_FB_PAGE: "Per Page",
  REQUEST_FINANCIAL_HARDSHIP_EXEMPTION:
    "Request a financial hardship exemption",
  FOUND_SITES_IN: "Found TAPinto sites in ",
  SITE: "TAPinto Site ",
  WAS_NOT_FOUND_IN: " was not found in ",
  EDIT: "Edit",
  PER_OCCURRENCE: "Per Occurrence",
  SUBMIT: "Submit",
  NO_COLUMNS: "No Columns Available",
  EXEMPTION_SITES_MESSAGE_ADVERTISER_1: "Publishing content on",
  EXEMPTION_SITES_MESSAGE_ADVERTISER_2:
    " where you are advertising is free. To submit and pay for additional sites, choose them below.",
  EXEMPTION_SITES_MESSAGE_CONTRIBUTOR_1: "Submitting content to",
  EXEMPTION_SITES_MESSAGE_CONTRIBUTOR_2:
    " where your exemption has been granted is free.  To submit and pay for additional sites, choose them below.",
  ADD_MORE: "Add more",
  OPENING_PREVIEW: "Opening Preview ...",
  MUST_AGREE_TO_TAPINTO_TERMS:
    "You must agree to TAPinto.net's Terms of Use and Privacy Policy.",
  MUST_AGREE_TO_TAPINTO_TERMS_WITH_TOWNS:
    "You must agree to TAPinto.net's Terms of Use and Privacy Policy and choose at least one town.",
  TELL_US_MORE_ABOUT:
    "Please, tell us more about your nonprofit or government.",
  EMPTY_DESCRIPTION_OR_NAME_ERROR:
    "Please provide a name and a description (up to 500 characters) of your non profit or government.",
  GOVERNEMENT_OR_NON_PROFIT_NAME:
    "What is your nonprofit's or government's name?",
  TOOLTIP_FOR_EXEMPTION_LINK:
    "You have already requested an exemption.  An exemption is a one-time offer.  Please email contact@tapinto.net if you have any questions or issues",
  SAVE_DRAFT: "Save Draft",
  SAVING_DRAFTS: "Saving Draft",
  YOUR_DRAFT_WAS_SAVED: "Your draft was successfully saved.",
  ERROR_SAVING_DRAFT: "Your draft could not be saved. Please try again later.",
  TOWN_OF_RESIDENCY: "Town of Residency",
  LTE_QUESTION:
    "If you are not submitting this letter to your town of residency, why should it be considered for publication?",
  LTE_QUESTION_PLACE_HOLDER: "200 characters max",
  PREVIEW_BUTTON:
    "This button is disabled because you are currently on preview.",
  TIME_15_MINUTES_STEP: " (in 15-minute increments)",
  EDIT_REAL_ESTATE_LISTING: "Editing Real Estate Listing",
  EDIT_EVENT: "Editing Event",
  EDIT_CLASSIFIED: "Editing Classified",
  EDIT_DIRECTORY: "Editing Directory",
  EDIT_MILESTONE: "Editing Milestone",
  EDIT_OBITUARY: "Editing Obituary",
  EDIT_PRESS_RELEASE: "Editing Press Release",
  EDIT_CANDIDATE_STATEMENT: "Editing Candidate Statement",
  EDIT_LETTER_TO_THE_EDITOR: "Editing Letter to The Editor",
  EDIT_COLUMN: "Editing Column",
  EXPIRE_CLASSIFIEDS: "Classifieds expire 30 days after publication",
  EXPIRE_REAL_ESTATES: "Real Estate Listings expire 90 days after publication",
  EXPIRE_BUSINESS_DIRECTORIES:
    "Business Directory Listings expire 1 year after publication",
  VIRTUAL_EVENT_URL: "Website or URL",
  EDIT: "Edit",
  CONTINUE: "Continue",
  EDIT_CONTENT: "Edit Content",
  DELETE_CONTENT: "Delete Content",
  EDIT_CONTENT_POP_UP_TEXT:
    "When you edit your content, it will be reconsidered for publication. During this time period, the content will no longer be live on the site. Once the content is republished, the content will be live.",
  DELETE_CONTENT_POP_UP_TEXT: "Are you sure you want to delete this content?",
  DELETE: "Delete",
  NON_PROFIT_CHECKBOX_LABEL:
    "I certify that I am submitting this content for or on behalf of a 501(c)(3) nonprofit organization. *",
  CANNOT_BE_ANONYMOUS: "The author cannot be anonymous.",
  DEFAULT_LOADING_LABELS: [
    "Don't exit your browser, we're saving your content",
    "Don't close this page, your content is being uploaded",
  ],
  SELECT_A_TOWN: "Select a town",
  SELECT_A_PUBLICATION: "Select a publication",
  PER_YEAR: "Per year",
  PUBLICATIONS_LIKE: "Publications like ",
  PUBLICATION: "Publication ",
  EVENT_SAME_CONTACT_INFO: "Same contact info seen by admin",
  LEGAL_NOTICE_TERMS:
    "I understand publication of this legal notice does not currently satisfy New Jersey state law requirements.",
};
export default diyLabels;
