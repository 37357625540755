import React, { useCallback, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Button from "../../../common/Button/Button";
import labels from "../../../config/labels";
import Tooltip from "../../../common/Tooltip/Tooltip";
import CloseCircle from "../../../common/Icons/CloseCircle";
import MultipleTownPickerWithChips from "../../TownPicker/MultipleWithChips/MultipleWithChips";
import { useStyles } from "./AddMoreTowns.style";
import { loadTownsWithoutClusters } from "../../../services/townService";
import { useTown } from "../../../context/TownContext";
import { buildChipDataForMultipleAutocomplete } from "../../../utils/helper";
import {
  parseTownsWithClustersForSelect,
  verifyAndInsertCurrentTownInTownsArray,
} from "../../../utils/helpers/townsHelper";

const AddMoreTowns = ({
  formData,
  handleTownSelect,
  loadingContent,
  addError,
  fetchUserSubscriptions,
  selectSettings,
  modifySelectSettings,
  town,
  setAddMoreTownsShow
}) => {
  const classes = useStyles();
  const [preselectedTowns, setPreselectedTowns] = useState([]);

  const townOptions = useCallback(async () => {
    const response = await loadTownsWithoutClusters();

    if (
      response?.status === 200 &&
      response?.data?.towns_without_clusters?.length
    ) {
      modifySelectSettings({
        visible: true,
        options: parseTownsWithClustersForSelect(
          response?.data?.towns_without_clusters
        ),
      });
    } else {
      addError({
        key: "townOptions",
        message: "Additional town options for dropdown could not be retrieved",
      });
    }
  }, [selectSettings.options]);

  const handleAddMoreTownsClick = async () => {
    loadingContent(true);

    await townOptions();
    let previousSubscriptions = await fetchUserSubscriptions();

    previousSubscriptions = verifyAndInsertCurrentTownInTownsArray({
      townsArray: previousSubscriptions,
      currentTown: town,
    });

    setPreselectedTowns(
      previousSubscriptions?.map((item) =>
        buildChipDataForMultipleAutocomplete(
          item.name,
          item.value,
          classes.chip
        )
      )
    );
    setAddMoreTownsShow(true);

    loadingContent(false);
  };

  const handleCloseAddMoreTowns = () => {
    setAddMoreTownsShow(false);
    modifySelectSettings({ visible: false });
  };

  return (
    <>
      {formData?.email ? (
        <Button
          level="simple_button"
          color="primary"
          children={labels.ADD_MORE_TOWNS}
          onClick={handleAddMoreTownsClick}
          classes={{
            root: clsx(
              classes.button,
              classes.button_label,
              classes.add_more_towns_button
            ),
          }}
          ariaLabel="submit subscription"
          disabled={selectSettings.visible}
        />
      ) : (
        <Tooltip
          contents={
            <div className={classes.tooltip_button}>
              <Button
                level="simple_button"
                color="primary"
                children={labels.ADD_MORE_TOWNS}
                classes={{
                  root: clsx(
                    classes.button,
                    classes.button_label,
                    classes.add_more_towns_button
                  ),
                }}
                ariaLabel="submit subscription disabled"
                disabled
              />
            </div>
          }
          title={
            <div className={classes.tooltip_message}>
              {labels.VALID_EMAIL_REQUIRED_TO_ADD_MORE_TOWNS}
            </div>
          }
          placement="right-end"
          arrow
        />
      )}

      {selectSettings.visible && (
        <>
          <Button
            level="simple_button"
            color="primary"
            children={
              <>
                <CloseCircle
                  fillColor="#f98c10"
                  className={classes.close_icon}
                />
                Cancel
              </>
            }
            onClick={handleCloseAddMoreTowns}
            classes={{
              root: clsx(
                classes.button,
                classes.button_label,
                classes.close_icon_button
              ),
            }}
          />

          <MultipleTownPickerWithChips
            options={selectSettings.options}
            handleSelect={(e) => handleTownSelect({ selectedTowns: e })}
            preselectedTowns={preselectedTowns}
          />
        </>
      )}
    </>
  );
};

AddMoreTowns.propTypes = {
  formData: PropTypes.shape({
    email: PropTypes.string,
    selectedTowns: PropTypes.array,
  }),
  handleTownSelect: PropTypes.func,
  loadingContent: PropTypes.func,
  addError: PropTypes.func,
  fetchUserSubscriptions: PropTypes.func,
};

export default AddMoreTowns;
