const promotionsLabels = {
  SECTION_STATUS: {
    SUCCESS: "Completed",
    ERROR: "There are items that require your attention",
  },
  HELPER_LINKS: {
    GAM_REPORT: "GAM Report",
    NEW_CONTRACT: "Online contract",
    EDITABLE_CONTRACT: "Editable contract",
  },
  SUMMARY_ACTIONS: {
    APPROVE: "Approve",
    APPROVING: "Approving...",
    APPROVED: "Approved",
    DISAPPROVE: "Disapprove",
    SEND_TO_GAM: "Send changes to GAM",
    SEND_TO_GAM_MOBILE: "Send to GAM",
    SENDING: "Sending...",
    COMPLETE: "Complete",
    COMPLETING: "Completing...",
    COMPLETED: "Completed",
    GENERATING: "Generating Billing Entry...",
    GENERATE: "Generate Billing Entry",
    REQUEST_REOPEN: "Request Reopen",
    ACTIVATE_OR_DIACTIVATE_FORBIDEN_MESSAGE:
      "You need more than 1 creative for this functionality",
    PAUSE: "Pause",
    DELIVER: "Deliver",
  },
  RECEIVES: "Receives",
  SEND_PREVIEW_E_BLAST: "Send preview E-blast",
  CREATIVES: {
    SIZE: "Size",
    TOWNS: "Towns",
    UPLOADED_FILE_SUCCESS: "Uploaded file successfully",
    CHOOSE_VIDEO_AD: "Choose video ad",
    CREATIVE_NAME: "Creative name",
    VIDEO_NAME: "Video name",
    RECOMMENDATION_VIDEO_TITLE: "Video recommendations",
    SECTION: "Section",
    SUBSECTION: "Subsection",
    AD_PLACEMENT: "Ad placement",
    RUN_ON_SITE: "Run on Site",
    SECTIONS: "Sections",
    SUBSECTIONS: "Subsections",
  },
  BILLING_INFO: {
    SPLITS: "Splits",
    SELECT: "Select",
    NO_TOWN_OR_PARTNER_SELECTED: "No town/partner selected",
    MONTH_S: "month(s)",
    REQUEST_CONTRACT_END_DATE_EXTENSION: "Request contract end date extension",
    REQUESTED_END_DATE: "Requested end date: ",
    PLEASE_WAIT_FOR_APPROVAL: "Please wait for approval.",
    REQUESTED_DATE_EXTENSION_TO: "Requested contract end date extension to ",
    DATE_EXTENSION_CONFIRMATION_MESSAGE:
      ":action the contract extension request?",
    REJECT_DATE_EXTENSION_CONFIRMATION_MESSAGE:
      "Reject contract end date extension",
    DATE_EXTENSION_ERROR: "Error sending your request. Please try again.",
    DATE_EXTENSION_SUCCESS: "Your request has been sent.",
    ADD_ADDITIONAL_TOWN_PARTNER: "Add additional town/partner",
    SPLIT_BY: "Splits by",
    NUMBER_OF_ENTRIES: "Number of entries",
    MONTHLY_TOTAL: "Monthly total",
    INSTALLMENT_AMOUNT: "Instalment amount",
    TOTAL_CONTRACT: "Total contract",
    INCREASE_EXTRA_AMOUNT: "Increase Extra Amount",
    REJECT_REQUEST_EXTRA_AMOUNT: "Reject",
    APPROVE_REQUEST_EXTRA_AMOUNT: "Approve",
    DAYS: "Days",
    DETAILS: "Details",
    NEW_CONTRACT_END_DATE: "New contract end date",
    CONTRACT_END_DATE: "Contract end date",
    REQUESTED: "Requested",
    STATUS: "Status",
    APPROVE_EXTRA_AMOUNT_CONFIRMATION: "Approve Extra Amount Confirmation",
    REJECT_EXTRA_AMOUNT_CONFIRMATION: "Reject Extra Amount Confirmation",
    APPROVE_EXTRA_AMOUNT_BODY:
      "Are you sure you want to approve this extra amount?",
    REJECT_EXTRA_AMOUNT_BODY:
      "Are you sure you want to reject this extra amount?",
    REQUEST_EXTENSION_NOTE:
      "Note: the number of months is calculated based on the selected billing frequency",
    RESET_TO_TAPINTO_COLOR: "Reset to TAPinto's color scheme",
    ALL_TOWNS: "All towns",
  },
  DIALOGS: {
    DESKTOP_LEADERBOARD_MESSAGE:
      "The Desktop Leaderboard needs a Mobile Leaderboard too, do you want to continue?",
    MOBILE_LEADER_BOARD_MESSAGE:
      "The Mobile Leaderboard needs a Desktop Leaderboard too, do you want to continue?",
    INCREASE_EXTRA_AMOUNT_TITLE: "Increase Extra Amount",
    ADDIONAL_DAYS: "Additional days",
    PRICE: "Price",
  },
  SPONSORS: {
    RECOMMENDATION_IMAGE_TITLE: "Sponsor image recommendations",
  },
  CREATED_BY: "Created by: ",
  NOTES: "Notes",
  RESERVATIONS: {
    GENARATING: "Generating...",
    VALIDATE_AND_GENERATE: "Validate & Generate",
    CHECK_AVAILABILITY: "Check Availability",
    CHECKING: "Checking...",
  },
  APPROVE_CONFIRMATION_MESSAGE_TITLE: "Approve Confirmation",
  APPROVE_CONFIRMATION_MESSAGE_CONTENT:
    "Are you sure you want to approve this file?",
  ARCHIVE: "Archive",
  INVOICES: {
    RECEIVE_PAYMENT_TITLE: "Receive entry Payment",
  },
  REVENUES: {
    APPROVE_ALL: "Approve All",
    DOWNLOAD_CSV: "Download CSV",
    HISTORY: "History",
    APPROVE: "Approve",
    RESEND: "Resend",
    REJECT: "Reject",
    RECEIVE_PAYMENT_TITLE_FOR_MOBILE: "Receive",
    BACK_TO_INDEX: "Back to Index",
    APPROVED_ON: "Approved on ",
    REJECTED_ON: "Rejected on ",
    REVENUES_CONFIRMATION: {
      approve: "Are you sure you want to approve revenues?",
      reject: "Are you sure you want to reject revenues?",
      resend: "Are you sure you want to re-send revenues?",
    },
    REJECT_REVENUES: "Reject revenues",
  },
  NO_DATA: "No data",
  FILE_NAME: "File name",
  FILE_NAMES: "File names",
  CONTRACT_START_DATE: "Contract Start Date",
  CONTRACT_DATE: "Contract Date",
  CURRENT_CYCLE: "Current cycle: ",
  EBLAST: {
    IMAGE_SIZE_MESSAGE: "All images uploaded on the text editor should be 600px width max"
  }
};

export default promotionsLabels;
