import React from "react";
import Typography from "../common/Typography/Typography";
import restClient from "../utils/restClient";
import withConfig from "../utils/withConfig";
import labels from "../config/labels";

const switchTown = (slug, envUrl) => {
  const townUrl = envUrl ? `${envUrl}towns/${slug}` : `/towns/${slug}`;

  if (window.top !== window.self) {
    window.open(townUrl, "_top");
  } else {
    window.location.href = townUrl;
  }
};

const switchLicenseTown = (url, envUrl) => {
  const townUrl = envUrl ? `${envUrl}${url}` : `/${slug}`;

  if (window.top !== window.self) {
    window.open(townUrl, "_top");
  } else {
    window.location.href = townUrl;
  }
};

const getLabelComponent = (townName, isClusterTown, areTownChoices) => {
  return (
    <Typography
      level="t2_text_2"
      color="black"
      {...(areTownChoices ? { bold: isClusterTown ? false : true } : {})}
    >
      {townName}
    </Typography>
  );
};

const getTownOption = (town, envUrl, isCluster) => {
  if (town?.url) {
    return {
      id: town.id,
      label: town.name_with_zip_code,
      action: () => switchLicenseTown(town.url, envUrl),
      labelComponent: getLabelComponent(
        town.name_with_zip_code,
        isCluster,
        true
      ),
      labelComponent: getLabelComponent(
        town.name_with_zip_code,
        isCluster,
        true
      ),
    };
  }
  return {
    id: isCluster ? town.parent_town_id : town.id,
    label: town.name_with_zip_code,
    action: () => switchTown(town.slug, envUrl),
    labelComponent: getLabelComponent(town.name_with_zip_code, isCluster, true),
  };
};

const getTownsAsItems = (towns, envUrl) => {
  const allTowns = [];
  towns.forEach((town) => {
    allTowns.push(getTownOption(town, envUrl, false));
    if (town.cluster_towns?.length > 0) {
      town.cluster_towns.forEach((cluster) => {
        allTowns.push(getTownOption(cluster, envUrl, true));
      });
    }
  });
  return allTowns;
};

const getDropdownChoices = (
  arrayOfObjects,
  setObjectId,
  areTownChoices = true
) => {
  let choices = [];
  choices = arrayOfObjects.map((object) => {
    const label =
      object.name === labels.HALSTON_MEDIA
        ? `${object.name} ${labels.HALSTON_MEDIA_EXTENDED_NAME}`
        : object.name;
    return {
      id: object.id,
      label,
      action: () => setObjectId(object.id),
    };
  });
  return choices;
};

const filterMainTownFromDropdownChoices = (
  townsWithClusters,
  selectedMainTown
) => {
  const filteredChoices = townsWithClusters.filter(
    (town) => town.id != selectedMainTown
  );
  return filteredChoices;
};

function buildStateObject(state, isSelectedStatus) {
  return {
    state: state.state,
    isSelected: isSelectedStatus,
    counties: state.counties,
  };
}

function buildTownsWithState(statesWithCountiesAndTowns, contributionType) {
  const fullStates = statesWithCountiesAndTowns
    .map((state) => {
      return {
        state: state.state,
        counties: state.counties.map((county) => {
          return {
            id: county.id,
            label: county.label,
            selected: county.selected,
            children: county.children.map((child) => {
              return {
                id: child.id,
                label: child.label,
                price: child.price,
                fb_price: child.fb_price,
                ads_free_price: child.ads_free_price,
                enews_price: child.enews_price,
                selected: child.selected,
                state: state.state,
                fb_selected: child.fb_selected,
                ads_free_selected: child.ads_free_selected,
                enews_selected: child.enews_selected,
                main_town_id: child.main_town_id,
                is_main_town_license: child.is_main_town_license,
                enews_selected: child.enews_selected,
              };
            }),
          };
        }),
      };
    })
    .filter(({ state }) =>
      contributionType === "legal_notice" ? state[1] === "NJ" : true
    );
  return fullStates;
}

const filterSelectedTownsFromOptions = (townsArray, selectedTownsArray) => {
  const filteredChoices = townsArray.filter(function (town) {
    return !selectedTownsArray.find(function (selectedTown) {
      return town.id === selectedTown.id;
    });
  });
  return filteredChoices;
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

const loadTownsWithClusters = async (forIndex = false, town_slug = null) => {
  try {
    const response = await restClient.get(
      withConfig("LOAD_TOWNS_WITH_CLUSTERS"),
      {
        towns_for_index: forIndex,
        town_slug,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const loadTownsWithoutClusters = async () => {
  try {
    const response = await restClient.get(
      withConfig("LOAD_TOWNS_WITHOUT_CLUSTERS"),
      {}
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const defaultThemeColors = {
  primary_color: "#F98C10",
  secondary_color: "#7FC035",
};

const loadCustomMasterheadButtons = async (townSlug) => {
  try {
    const response = await restClient.get(
      withConfig("LOAD_CUSTOM_MASTERHEAD_BUTTONS").replace(":id", townSlug)
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const loadCustomMasterheadButtonsWithoutTown = async (townSlug) => {
  try {
    const response = await restClient.get(
      withConfig("LOAD_CUSTOM_MASTERHEAD_BUTTONS_WITH_NO_TOWN")
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const fetchTheme = async (slug = null) => {
  try {
    const params = slug ? { id: slug } : {};
    const response = await restClient.get(withConfig("FETCH_THEME"), params);

    return response;
  } catch (error) {
    throw error;
  }
};

const loadThemeTexts = async (slug = null) => {
  try {
    const params = slug ? { id: slug } : {};
    const response = await restClient.get(
      withConfig("FETCH_THEME_TEXTS"),
      params
    );

    return response;
  } catch (error) {
    throw error;
  }
};

const loadNearbyTowns = async (town_id) => {
  try {
    const response = await restClient.get(withConfig("NEARBY_TOWNS"), {
      id: town_id,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
const loadMasterheadDesign = async (town_id) => {
  try {
    const response = await restClient.get(
      withConfig("LOAD_CUSTOM_MASTERHEAD_LICENSE_DESIGN"),
      {
        id: town_id,
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export {
  getTownsAsItems,
  getDropdownChoices,
  filterMainTownFromDropdownChoices,
  buildStateObject,
  buildTownsWithState,
  filterSelectedTownsFromOptions,
  getTownOption,
  reorder,
  loadTownsWithClusters,
  loadTownsWithoutClusters,
  loadCustomMasterheadButtons,
  loadCustomMasterheadButtonsWithoutTown,
  loadThemeTexts,
  fetchTheme,
  loadNearbyTowns,
  loadMasterheadDesign,
};
