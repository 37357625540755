import React, { useEffect, useMemo, useState } from "react";
import Grid from "../../../common/Grid/Grid";
import Checkbox from "../../../common/Checkbox/Checkbox";
import Typography from "../../../common/Typography/Typography";
import { useStyles } from "./OptionalSettings.style";
import labels from "../../../config/labels";
import { useDiy } from "../../../context/DiyContext";
import DatePicker from "../../../common/DatePicker/DatePicker";
import Button from "../../../common/Button/Button";
import ButtonIcon from "../../../common/Button/ButtonIcon";
import CloseIcon from "../../../common/Icons/Close";
import clsx from "clsx";
import {
  ADS_FREE_TOWN_IDS,
  INCLUDE_IN_ENEWS,
  ENEWS_TOWN_IDS,
  AD_FREE,
  GREEN_HEX,
  FACEBOOK_EXCERPT,
  FACEBOOK_TOWN_IDS,
  FACEBOOK_URL,
  POST_ON_FACEBOOK,
  PRESS_RELEASE,
  COLUMN,
  EVENT,
  CLASSIFIED,
  BUSINESS_DIRECTORY_LISTING,
  REAL_ESTATE_LISTING,
  OBITUARY,
  CANDIDATE_STATEMENTS,
  MILESTONE,
} from "../diyConstants";
import useSubmitableContentForm from "../useSubmitableContentForm";
import TextField from "../../../common/TextField/TextField";

const today = new Date();
const twoDaysLater = new Date();
twoDaysLater.setDate(today.getDate() + 2);

const OptionalSettings = ({ content, isLicensed, isAdvertiser, formType }) => {
  const classes = useStyles();

  const {
    contributionType,
    optionalPrices,
    checkInludeInEnews,
    checkAdsFreeOption,
    checkFacebookOption,
    handleAddEnewsDate,
    handleRemoveEnewsDate,
    handleUpdateEnewsDate,
    selectedEnewsDatesForDatabase,
    setSelectedEnewsDatesForDatabase,
    town,
  } = useDiy();
  const {
    enews_price: enewsPrice,
    ads_free_price: adsFreePrice,
    share_fb_price: shareFbPrice,
  } = optionalPrices ?? {};
  const [enews, setEnews] = useState(false);
  const [adFree, setAdFree] = useState(false);
  const [facebookChecked, setFacebookChecked] = useState(false);

  useEffect(() => {
    setEnews(content?.paid_for_enews_dates);
    setAdFree(content?.ad_free);
    setFacebookChecked(content?.is_selected);
  }, [content]);

  const { setProperty } = useSubmitableContentForm();

  const getFormTypeLabelByFormType = () => {
    switch (formType) {
      case COLUMN:
        return labels.COLUMN;
      case CANDIDATE_STATEMENTS:
        return labels.CANDIDATE_STATEMENT;
      default:
        return labels.PRESS_RELEASE;
    }
  };

  const getFormTypeLabel = () => {
    switch (contributionType) {
      case PRESS_RELEASE:
        return getFormTypeLabelByFormType();
      case EVENT:
        return labels.EVENT;
      case CLASSIFIED:
        return labels.CLASSIFIED;
      case BUSINESS_DIRECTORY_LISTING:
        return labels.BUSINESS_DIRECTORY_LISTING;
      case OBITUARY:
        return labels.OBITUARY;
      case REAL_ESTATE_LISTING:
        return labels.REAL_ESTATE_LISTING;
      case MILESTONE:
        return labels.MILESTONE;
      default:
        break;
    }
  };
  const options = [
    {
      id: "includeEnews",
      title: labels.TITLE_INCLUDE_IN_ENEWS,
      description: labels.DESCRIPTION_ENEWS_PRICE,
      price: enewsPrice == 0.0 ? "Free" : `$${enewsPrice}`,
      priceDetails: labels.PRICE_DETAILS_ENEWS,
      enewsDatesLimit: labels.ENEWS_DATES_LIMIT,
    },
    {
      id: "adFree",
      title: labels.TITLE_AD_FREE,
      description: labels.DESCRIPTION_AD_FREE_PRICE,
      price: adsFreePrice == 0.0 ? "Free" : `$${adsFreePrice}`,
      priceDetails: labels.PRICE_DETAILS_AD_FREE,
      adFreeLimit: labels.ADVERTISERS_AD_FREE_LIMIT,
    },
    {
      id: "shareFB",
      title: isLicensed ? labels.TITLE_SHARE_FB_LICENSE : labels.TITLE_SHARE_FB,
      description: `${
        labels.DESCRIPTION_SHARE_FB_PART_1
      } ${getFormTypeLabel()} ${
        isLicensed
          ? labels.DESCRIPTION_SHRE_FB_LICENSE
          : labels.DESCRIPTION_SHARE_FB
      }`,
      price: shareFbPrice == 0.0 ? "Free" : `$${shareFbPrice}`,
      priceDetails: labels.PRICE_DETAILS_SHARE_FB,
    },
  ];

  const showAdFree = useMemo(() => {
    return ["press-release", "column", "event", "classified"].includes(
      contributionType
    );
  }, [contributionType]);

  const showIncludeEnews = useMemo(() => {
    return [
      "press-release",
      "event",
      "real-estate-listing",
      "business-directory-listing",
      "classified",
      "column",
    ].includes(contributionType);
  }, [contributionType]);

  const showShareFacebook = useMemo(() => {
    return [
      "press-release",
      "candidate-statements",
      "business-directory-listing",
      "obituary",
      "event",
      "classified",
      "real-estate-listing",
      "milestone",
    ].includes(contributionType);
  }, [contributionType]);

  const visibleOptions = useMemo(() => {
    if (town?.slug === "nyvt-media") {
      return options.filter(
        (option) => option.id === "adFree" || option.id === "shareFB"
      );
    }

    return options.filter(
      (option) =>
        (option.id !== "adFree" || showAdFree) &&
        (option.id !== "includeEnews" || showIncludeEnews) &&
        (option.id !== "shareFB" || showShareFacebook)
    );
  }, [options, showAdFree, showIncludeEnews, showShareFacebook]);

  const handleCheckboxChange = (id) => (event) => {
    if (id === "adFree") {
      setAdFree(event.target.checked);
    } else if (id === "includeEnews") {
      setEnews(event.target.checked);
    } else if (id === "shareFB") {
      setFacebookChecked(event.target.checked);
    }
  };

  useEffect(() => {
    if (!enews) {
      setProperty(ENEWS_TOWN_IDS, []);
    }
    setProperty(INCLUDE_IN_ENEWS, enews === true ? 1 : 0);
    checkInludeInEnews();
  }, [enews]);

  useEffect(() => {
    if (!adFree) {
      setProperty(ADS_FREE_TOWN_IDS, []);
    }
    setProperty(AD_FREE, adFree === true ? 1 : 0);
    checkAdsFreeOption();
  }, [adFree]);

  useEffect(() => {
    if (!facebookChecked) {
      setProperty(FACEBOOK_TOWN_IDS, []);
    }
    setProperty(POST_ON_FACEBOOK, facebookChecked === true ? 1 : 0);
    checkFacebookOption();
  }, [facebookChecked]);

  useEffect(() => {
    if (content?.enews_dates?.length) {
      setSelectedEnewsDatesForDatabase(() => {
        return content?.enews_dates?.map((date) => {
          return {
            id: date?.id,
            date: date?.date,
          };
        });
      });
    }
  }, [content]);

  return (
    <div className={classes.container}>
      <Typography level={"medium_20_px"} className={classes.title}>
        {labels.TITLE}
      </Typography>
      {visibleOptions.map((option) => (
        <Grid container key={option.id} className={classes.optionRow}>
          <Grid item xs={1} lg={1} md={1} className={classes.checkboxWrapper}>
            <Checkbox
              onChange={handleCheckboxChange(option.id)}
              checkboxColor={GREEN_HEX}
              checked={
                option.id === "adFree"
                  ? adFree
                  : option.id === "includeEnews"
                  ? enews
                  : option.id === "shareFB"
                  ? facebookChecked
                  : false
              }
            />
          </Grid>
          <Grid item xs={9} lg={9} md={9} className={classes.textWrapper}>
            <Typography level={"medium_18_px"} className={classes.optionTitle}>
              {option.title}
            </Typography>
            <Typography
              level={"medium_16_px"}
              className={classes.optionDescription}
            >
              {option.description}
            </Typography>
            {isAdvertiser && (
              <Typography
                level={"medium_16_px"}
                className={classes.optionDescription}
                bold
              >
                {option.enewsDatesLimit || option.adFreeLimit}
              </Typography>
            )}
          </Grid>
          <Grid item xs={2} lg={2} md={2} className={classes.priceWrapper}>
            <Typography
              level={"medium_18_px"}
              className={classes.optionPrice}
              bold
            >
              {option.price}
            </Typography>
            <Typography className={classes.priceDetails}>
              {option.priceDetails}
            </Typography>
          </Grid>
          {option.id === "includeEnews" && enews && (
            <Grid container key={option.id} className={classes.optionRow}>
              <Grid container spacing={2} direction="column">
                {selectedEnewsDatesForDatabase.map((date, index) => (
                  <Grid
                    container
                    key={index}
                    alignItems="center"
                    spacing={2}
                    className={classes.date_container}
                  >
                    <Grid
                      item
                      xs={7}
                      sm={10}
                      md={4}
                      lg={4}
                      className={classes.textWrapper}
                    >
                      {index === 0 && (
                        <Typography
                          level={"medium_18_px"}
                          className={classes.optionTitle}
                        >
                          {labels.ENEWS_DATE_TITLE}
                        </Typography>
                      )}
                      <DatePicker
                        className={classes.pickers}
                        value={date?.date}
                        onChange={(newDate) =>
                          handleUpdateEnewsDate(index, newDate, date?.id)
                        }
                        disablePast={true}
                        minDate={twoDaysLater}
                      />
                    </Grid>

                    {index > 0 && (
                      <Grid
                        item
                        xs={1}
                        md={1}
                        lg={1}
                        className={classes.delete_container}
                      >
                        <ButtonIcon
                          onClick={() => handleRemoveEnewsDate(index)}
                          withDefaultStyles
                          children={<CloseIcon />}
                          className={classes.delete_icon}
                        />
                      </Grid>
                    )}
                  </Grid>
                ))}
                <Grid
                  item
                  xs={5}
                  lg={5}
                  md={5}
                  className={classes.container_add_button}
                >
                  <Button
                    onClick={handleAddEnewsDate}
                    level="simple_button"
                    children={labels.ADD_MORE}
                    classes={{
                      root: clsx(classes.button, classes.add_more_button),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {option.id === "shareFB" && facebookChecked && (
            <div className={classes.facebook_excerpt}>
              <Typography level="h5_sub_header_2" color="facebookBlue" bold>
                {labels.EXCERPT}
              </Typography>
              <TextField
                isMultiline
                className={classes.excerpt}
                onChange={(event) =>
                  setProperty(FACEBOOK_EXCERPT, event.target.value)
                }
                defaultValue={content?.excerpt}
              />
              <Typography
                level="h5_sub_header_2"
                color="facebookBlue"
                bold
                className={classes.excerpt_header}
              >
                {labels.FACEBOOK_URL}
              </Typography>
              <TextField
                className={classes.facebook_url}
                defaultValue={
                  !!content?.facebook_client_url
                    ? content?.facebook_client_url
                    : "https://www.facebook.com/"
                }
                onChange={(event) =>
                  setProperty(FACEBOOK_URL, event.target.value)
                }
              />
            </div>
          )}
        </Grid>
      ))}
    </div>
  );
};

export default OptionalSettings;
